import { FieldPropsConfig } from 'ui/forms/custom/types';

export const commonFieldsProps: Record<string, FieldPropsConfig> = {
  email: {
    fieldType: 'input',
    validations: ['email'],
    maxLength: 200,
  },
  orderEmail: {
    fieldType: 'input',
    validations: ['email'],
    maxLength: 200,
  },
  firstName: {
    fieldType: 'input',
    validations: ['name'],
    maxLength: 50,
  },
  lastName: {
    fieldType: 'input',
    validations: ['name'],
    maxLength: 50,
  },
  password: {
    fieldType: 'password',
    validations: ['password'],
    minLength: 8,
    maxLength: 255,
  },
  billingPostalCode: {
    fieldType: 'input',
    validations: ['postalCode'],
    maxLength: 50,
  },
  backToMyAccount: {
    fieldType: 'link',
    href: '/account/',
  },
  cancel: {
    fieldType: 'link',
    variant: 'primary',
    href: '/account',
  },
  phone: {
    fieldType: 'input',
    validations: ['phoneNumber'],
  },
  orderNo: {
    fieldType: 'input',
    validations: ['safeInput', 'orderNumberValidation'],
  },
  returnOrderLookup: {
    variant: 'primary',
  },
  returnsOrderExplainerModal: {
    name: 'returnsOrderExplainerModal',
    fieldType: 'text-to-modal',
    text: 'returnWhereToFindOrder',
    modalTitle: 'returnWhereToFindOrder',
    modalDescription: 'returnWhereToFindOrderExplainer',
  },
};
